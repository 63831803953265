import { tournamentsApi } from '~/src/features/tournaments/infrastructure/api';
import { saveInvoices } from '~/src/features/invoices/infrastructure/store';

export const setUpdateBalance = () => {
	const updateBalance = tournamentsApi.updateTournametBalance();

	return async (tournamentId: string) => {
		const result = await updateBalance(tournamentId);

		if (result.isRight()) {
			saveInvoices(result.value);
		}
	};
};
